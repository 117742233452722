import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import AppHeaderSistema from "./layout/AppHeaderSistema";
import AppFooterSistema from "./layout/AppFooterSistema";
import Components from "./views/Components.vue";
import Empresa from "./views/Empresa.vue";
import Parceiros from "./views/Parceiros.vue";
import Reunioes from "./views/Reunioes.vue";
import Orcamento from "./views/Orcamento.vue";
import Index from "./views/Index.vue";
import Login from "./views/Login.vue";
import Admsite from "./views/Admsite.vue";
import Contato from "./views/Contato.vue";
import Utilidades from "./views/Utilidades.vue";
import dotenv from 'dotenv';

dotenv.config();

Vue.use(Router);

const meta = {
  title: process.env.VUE_APP_SITE_TITLE,
  metaTags: [{
      name: 'description',
      content: process.env.VUE_APP_SITE_DESC
    },
    {
      property: 'og:description',
      content: process.env.VUE_APP_SITE_DESC
    }
  ]
};

const routes = [{
    path: "/",
    name: "index",
    components: {
      header: AppHeader,
      default: Index,
      footer: AppFooter
    },
    meta: meta
  },
  {
    path: "/empresa",
    name: "empresa",
    components: {
      header: AppHeader,
      default: Empresa,
      footer: AppFooter
    },
    meta: meta
  },
  {
    path: "/parceiros",
    name: "parceiros",
    components: {
      header: AppHeader,
      default: Parceiros,
      footer: AppFooter
    },
    meta: meta
  },
  {
    path: "/reunioes",
    name: "reunioes",
    components: {
      header: AppHeader,
      default: Reunioes,
      footer: AppFooter
    },
    meta: meta
  },
  {
    path: "/utilidades",
    name: "utilidades",
    components: {
      header: AppHeader,
      default: Utilidades,
      footer: AppFooter
    },
    meta: meta
  },
  {
    path: "/orcamento",
    name: "orcamento",
    components: {
      header: AppHeader,
      default: Orcamento,
      footer: AppFooter
    },
    meta: meta
  },
  {
    path: "/login",
    name: "login",
    components: {
      header: AppHeader,
      default: Login,
      footer: AppFooter
    },
    meta: meta
  },
  {
    path: "/contato",
    name: "contato",
    components: {
      header: AppHeader,
      default: Contato,
      footer: AppFooter
    },
    meta: meta
  },
  {
    path: "/admsite",
    name: "admsite",
    components: {
      header: AppHeaderSistema,
      default: Admsite,
      footer: AppFooterSistema
    },
    meta: meta
  }
];

const router = new Router({
  routes,
  mode: 'history',
  scrollBehavior: to => {
    if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});





export default router;