<template>
  <section class="section-shaped overflow-hidden my-0" :class="dadosDepoimentos.classebg">
    <div class="py-0">
      <div class="row">
        <div class="col-lg-12 mb-lg-auto">
          <div class="container">
            <h2
              class="my-5 text-center"
              :class="dadosDepoimentos.titulo.classe"
            >{{dadosDepoimentos.titulo.texto}}</h2>
            <div class="mb-5">
              <carousel :autoplay="true" :perPage="1" paginationActiveColor="#fb6340">
                <slide v-for="item in dadosDepoimentos.depoimentos" :key="item.id">
                  <div class="testimonial-slides text-center">
                    <p :class="dadosDepoimentos.classetexto" v-html="item.texto"></p>
                    <span :class="dadosDepoimentos.classenome">
                      <b>{{item.nome}}</b>
                      <br />
                      <small :class="dadosDepoimentos.classecargo">{{item.cargo}}</small>
                    </span>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import service from "../../services";
import { Carousel, Slide } from "vue-carousel";

export default {
  data() {
    return {
      dadosDepoimentos: {
        titulo: {}
      }
    };
  },
  methods: {
    gotoAnyWhere(link) {
      console.log(link);
    }
  },
  mounted() {
    service.getJson("depoimentos").then(res => {
      this.dadosDepoimentos = res;
    });
  },
  components: {
    Slide,
    Carousel
  }
};
</script>
<style>
</style>
