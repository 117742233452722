<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section mb-0" :style="`background-image: url(${parceiros.banner_bg})`">
        <div class="container mt-2">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12 pt-5">
                <h1 class="display-3 text-white my-5 py-5 text-center">{{parceiros.titulo}}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>

    <section class="section section-lg" v-if="parceiros.parceiros.length>0">
      <div class="container">
        <div class="row">
          <div class="col" v-for="item in parceiros.parceiros" :key="item.id">
            <div class="px-4">
              <img
                v-if="item.logo!=''"
                v-lazy="item.logo"
                class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                style="width: 200px;"
              >
              <div class="pt-4 text-center">
                <h5 class="title">
                  <span class="d-block mb-1">{{item.nome}}</span>
                  <small class="h6 text-muted">{{item.atuacao}}</small>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <calltoaction></calltoaction>
  </div>
</template>

<script>
import service from "../services";
import calltoaction from "./components/calltoaction";

export default {
  data() {
    return {
      parceiros: {
        parceiros: []
      }
    };
  },
  methods: {
    gotoAnyWhere(link) {
      console.log(link);
    }
  },
  mounted() {
    service.getJson("parceiros").then(res => {
      this.parceiros = res;
    });

    service.getJson("geral").then(res => {
      this.geral = res;
    });
  },
  components: { calltoaction }
};
</script>
