<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section mb-0" :style="`background-image: url(${utilidades.banner_bg})`">
        <div class="container mt-2">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12 pt-5">
                <h1 class="display-3 text-white my-5 py-5 text-center">{{utilidades.titulo}}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->

      <section class="section section-components">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="card shadow p-5">
                <h3 class="h4 text-warning font-weight-bold mb-4">Links Uteis</h3>
                <ul class="list-group">
                  <li class="list-group-item" v-for="item in utilidades.links" :key="item.id">
                    <i class="fa fa-link mr-1"></i>
                    <a :href="item.link" target="_blank">{{item.nome}}</a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card shadow p-5">
                <h3 class="h4 text-warning font-weight-bold mb-4">Arquivos Uteis</h3>
                <ul class="list-group">
                  <li class="list-group-item" v-for="item in utilidades.arquivos" :key="item.id">
                    <i class="fa fa-download mr-1"></i>
                    <a :href="item.link" target="_blank">{{item.arquivo}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <calltoaction></calltoaction>
  </div>
</template>

<script>
import service from "../services";
import calltoaction from "./components/calltoaction";
import Modal from "@/components/Modal.vue";

export default {
  data() {
    return {
      utilidades: {}
    };
  },
  methods: {},
  mounted() {
    service.getJson("utilidades").then(res => {
      this.utilidades = res;
    });

    service.getJson("geral").then(res => {
      this.geral = res;
    });
  },
  components: { calltoaction, Modal }
};
</script>
