<template>
  <header class="header-global">
    <div v-if="exibeTelefones" class="telefonestopo p-2" :class="classTopo">
      <div class="container">
        <div class="row">
          <div class="col">{{emailPrincipal}}</div>
          <div class="col text-right">
            <span v-for="(item, index) in telefones" :key="item.id">
              {{ item.numero }}
              <span v-if="index != telefones.length - 1">|</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <base-nav class="navbar-main" transparent type effect="light" expand>
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img :src="logoPrincipal.endereco" :alt="logoPrincipal.title" :title="logoPrincipal.title">
      </router-link>

      <div class="row" slot="content-header" slot-scope="{closeMenu}">
        <div class="col-6 collapse-brand">
          <a :href="logoMenu.link">
            <img :src="logoMenu.endereco" :alt="logoMenu.title" :title="logoMenu.title">
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <template v-for="item in arrmenus.menus">
          <base-dropdown tag="li" class="nav-item" :key="item.id" v-if="item.submenus">
            <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
              <i class="ni ni-collection d-lg-none"></i>
              <span class="nav-link-inner--text">{{item.texto}}</span>
            </a>
            <template v-for="itemsubmenu in item.submenus">
              <router-link
                v-if="!verificaLinkExterno(itemsubmenu.link)"
                :to="itemsubmenu.link"
                class="dropdown-item"
                :key="itemsubmenu.id"
              >{{itemsubmenu.texto}}</router-link>
              <a
                v-else
                :key="itemsubmenu.id"
                :href="itemsubmenu.link"
                class="dropdown-item"
              >{{itemsubmenu.texto}}</a>
            </template>
          </base-dropdown>
          <li :key="item.id" v-if="!item.submenus && !item.destaque">
            <router-link
              v-if="!verificaLinkExterno(item.link)"
              :to="item.link"
              class="nav-link"
              :key="item.id"
            >{{item.texto}}</router-link>
            <a v-else :key="item.id" :href="item.link" class="nav-link">{{item.texto}}</a>
          </li>
          <li :key="item.id" v-if="item.destaque" class="nav-item d-none d-lg-block ml-lg-4">
            <router-link
              v-if="!verificaLinkExterno(item.link)"
              :to="item.link"
              class="btn btn-icon"
              :key="item.id"
              :class="item.classe"
            >
              <span class="btn-inner--icon" v-if="item.icone">
                <i class="fa mr-2" :class="`fa-${item.icone}`"></i>
              </span>
              <span class="nav-link-inner--text">{{item.texto}}</span>
            </router-link>
            <a v-else :key="item.id" :href="item.link" class="btn btn-icon" :class="item.classe">
              <span class="btn-inner--icon" v-if="item.icone">
                <i class="fa mr-2" :class="`fa-${item.icone}`"></i>
              </span>
              <span class="nav-link-inner--text">{{item.texto}}</span>
            </a>
          </li>
        </template>
      </ul>
      <ul v-if="arrmenus.redessociais" class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item" v-for="item in arrmenus.redessociais" :key="item.id">
          <a
            class="nav-link nav-link-icon"
            :href="item.link"
            target="_blank"
            rel="noopener"
            data-toggle="tooltip"
            :title="item.title"
          >
            <i :class="item.icone"></i>
            <span class="nav-link-inner--text d-lg-none">{{item.nome}}</span>
          </a>
        </li>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import service from "../services";

export default {
  data() {
    return {
      arrmenus: {
        redessociais: []
      },
      exibeTelefones: false,
      logos: [],
      telefones: [],
      emails: [],
      logoPrincipal: {},
      logoMenu: {},
      classTopo: "",
      emailPrincipal: ""
    };
  },
  methods: {
    verificaLinkExterno(link) {
      if (link.substring(0, 7) == "http://") {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    service.getJson("menu").then(res => {
      this.arrmenus = res;
    });

    service.getJson("geral").then(res => {
      this.exibeTelefones = res.estrutura.exibetelefonetopo;
      this.classTopo = res.estrutura.classetopo;
      this.telefones = res.telefones;
      this.logos = res.logos;
      this.emails = res.emails;
      this.logoPrincipal = this.logos.filter(x => x.posicao == "topo")[0];
      this.logoMenu = this.logos.filter(x => x.posicao == "menumobile")[0];

      this.emailPrincipal = this.emails.filter(
        x => x.tipo == "principal"
      )[0].email;
    });
    //console.log(this.logoMenu)
  },
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  }
};
</script>
<style>
.telefonestopo {
  font-size: 12px;
}
</style>
