<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section mb-0" :style="`background-image: url(${reunioes.banner_bg})`">
        <div class="container mt-2">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12 pt-5">
                <h1 class="display-3 text-white my-5 py-5 text-center">{{reunioes.titulo}}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>

    <section class="section section-lg">
      <div class="container">
        <div class="row">
          <div class="col mb-4">
            <input
              type="text"
              class="form-control"
              id="formGroupExampleInput"
              placeholder="Pesquise por data, local ou ata"
              v-on:keyup="keymonitor"
              v-model="search"
            >
          </div>
        </div>
        <div class="row" v-if="reunioesFiltred.length>0">
          <div class="col-md-3" v-for="item in reunioesFiltred" :key="item.id">
            <div class="card px-4">
              <img
                v-if="item.fotoprincipal!=''"
                v-lazy="item.fotoprincipal"
                class="mt-4 img-center rounded img-fluid shadow shadow-lg--hover"
              >
              <div class="pt-4 text-center">
                <h5 class="title">
                  <span class="d-block mb-1">{{item.data}} às {{item.hora}}</span>
                  <small class="h6 text-muted">{{item.local}}</small>
                </h5>
                <base-button block type="dark" class="mb-3" @click="openModal(item)">
                  <span class="btn-inner--icon">
                    <i class="fa fa-search"></i>
                  </span>
                </base-button>
                <modal :show.sync="modal" modal-classes="modal-lg">
                  <h6 slot="header" class="modal-title" id="modal-title-default">{{titleModal}}</h6>
                  <div class="row">
                    <div class="col" v-for="item in fotosModal" :key="item.id">
                      <img
                        v-if="item!=''"
                        v-lazy="item"
                        class="mt-4 img-center rounded img-fluid shadow shadow-lg--hover"
                      >
                    </div>
                  </div>

                  <p class="mt-5" v-html="ataModal"></p>
                  <hr>
                  <div class="text-left">
                    <h6>Participantes</h6>
                    <div v-for="item in participantesModal" :key="item.id">{{item}}</div>
                  </div>
                </modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <calltoaction></calltoaction>
  </div>
</template>

<script>
import service from "../services";
import calltoaction from "./components/calltoaction";
import Modal from "@/components/Modal.vue";

export default {
  data() {
    return {
      reunioes: {},
      modal: false,
      titleModal: "",
      ataModal: "",
      fotosModal: [],
      participantesModal: [],
      search: "",
      reunioesFiltred: []
    };
  },
  methods: {
    openModal(item) {
      this.modal = true;
      this.titleModal = "Reunião em " + item.data + " às " + item.hora;
      this.ataModal = item.ata;
      this.fotosModal = item.fotos;
      this.participantesModal = item.participantes;
    },
    keymonitor: function(event) {
      console.log(this.search);
      this.reunioesFiltred = this.reunioes.reunioes.filter(
        x =>
          x.data.indexOf(this.search) > -1 ||
          x.local.indexOf(this.search) > -1 ||
          x.ata.indexOf(this.search) > -1
      );
    }
  },
  mounted() {
    service.getJson("reunioes").then(res => {
      this.reunioes = res;
      this.reunioesFiltred = this.reunioes.reunioes;
    });

    service.getJson("geral").then(res => {
      this.geral = res;
    });
  },
  components: { calltoaction, Modal }
};
</script>
