<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section mb-0" :style="`background-image: url(${bg_banner})`">
        <div class="container mt-2">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12 pt-5">
                <h1 class="display-3 text-white my-5 py-5 text-center">{{empresa.dados.titulo}}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>

    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-12" v-if="empresa.dados.imagem!=''">
            <img :src="empresa.dados.imagem" class="img-fluid floating">
          </div>
          <div class="col-md-12" v-for="item in textos" :key="item.id">
            <div class="pr-md-5">
              <h3>{{item.titulo}}</h3>
              <p v-html="item.texto"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-lg bg-secondary" v-if="empresa.time.length>0">
      <div class="container">
        <div class="row justify-content-center text-center mb-lg">
          <div class="col-lg-8">
            <h2 class="display-3">Nosso Time</h2>
          </div>
        </div>
        <div class="row">
          <div class="col" v-for="item in empresa.time" :key="item.id">
            <div class="px-4">
              <img
                v-if="item.foto!=''"
                v-lazy="item.foto"
                class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                style="width: 200px;"
              >
              <div class="pt-4 text-center">
                <h5 class="title">
                  <span class="d-block mb-1">{{item.nome}}</span>
                  <small class="h6 text-muted">{{item.cargo}}</small>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <calltoaction></calltoaction>
  </div>
</template>

<script>
import service from "../services";
import calltoaction from "./components/calltoaction";

export default {
  data() {
    return {
      empresa: {
        dados: {},
        textos: [],
        time: []
      },
      textos: [],
      geral: {},
      bg_banner: ""
    };
  },
  methods: {
    gotoAnyWhere(link) {
      console.log(link);
    }
  },
  created() {
    service.getJson("empresa").then(res => {
      this.empresa = res;
      this.bg_banner = this.empresa.dados.banner_bg;

      this.textos = this.empresa.textos.sort(function(a, b) {
        return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0;
      });
    });

    service.getJson("geral").then(res => {
      this.geral = res;
    });
  },
  components: { calltoaction }
};
</script>
