<template>
  <header class="header-global">
    <base-nav class="navbar-main" transparent type effect="light" expand>
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img :src="logoPrincipal.endereco" :alt="logoPrincipal.title" :title="logoPrincipal.title">
      </router-link>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <li class>
          <h2 class="text-center text-white">GERENCIADOR DO SITE</h2>
        </li>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import service from "../services";

export default {
  data() {
    return {
      arrmenus: {
        redessociais: []
      },
      exibeTelefones: false,
      logos: [],
      telefones: [],
      emails: [],
      logoPrincipal: {},
      logoMenu: {},
      classTopo: "",
      emailPrincipal: ""
    };
  },
  methods: {
    verificaLinkExterno(link) {
      if (link.substring(0, 7) == "http://") {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    service.getJson("menu").then(res => {
      this.arrmenus = res;
    });

    service.getJson("geral").then(res => {
      this.exibeTelefones = res.estrutura.exibetelefonetopo;
      this.classTopo = res.estrutura.classetopo;
      this.telefones = res.telefones;
      this.logos = res.logos;
      this.emails = res.emails;
      this.logoPrincipal = this.logos.filter(x => x.posicao == "topo")[0];
      this.logoMenu = this.logos.filter(x => x.posicao == "menumobile")[0];

      this.emailPrincipal = this.emails.filter(
        x => x.tipo == "principal"
      )[0].email;
    });
    //console.log(this.logoMenu)
  },
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  }
};
</script>
<style>
.telefonestopo {
  font-size: 12px;
}
</style>
