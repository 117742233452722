<template>
  <section class="section bg-dark section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0"
          >
            <template>
              <form role="form" @submit="onSubmit" @reset="onReset" v-if="show">
                <base-input
                  alternative
                  class="mb-3"
                  placeholder="Email"
                  v-model="form.login"
                  addon-left-icon="ni ni-email-83"
                ></base-input>
                <base-input
                  alternative
                  type="password"
                  placeholder="Password"
                  v-model="form.senha"
                  addon-left-icon="ni ni-lock-circle-open"
                ></base-input>
                <div class="text-center">
                  <b-button
                    type="submit"
                    variant="success"
                    class="btn-block"
                    v-if="aguarde==false"
                  >Login</b-button>
                  <b-button type="submit" variant="success" class="btn-block" v-else disabled>
                    <i class="fa fa-spinner"></i> Aguarde...
                  </b-button>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import service from "../services";
import moment from "moment";
import swal from "sweetalert";
export default {
  data() {
    return {
      aguarde: false,
      show: true,
      associe: {},
      form: {
        login: "",
        Data: moment().format("DD/MM/YYYY HH:mm:ss"),
        senha: ""
      }
    };
  },
  methods: {
    onSubmit(evt) {
      this.aguarde = true;
      evt.preventDefault();
      service
        .post("Adm", "MakeLogin", {
          site: process.env.VUE_APP_SITE,
          dadoForm: this.form
        })
        .then(() => {
          this.aguarde = false;
          location.href = "../admsite";
        })
        .catch(error => {
          this.aguarde = false;
          swal({
            title: "Oops!",
            text: error,
            icon: "error",
            button: "Continuar..."
          });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.login = "";
      this.form.Data = moment().format("DD/MM/YYYY HH:mm:ss");
      this.form.senha = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  mounted() {}
};
</script>
<style>
</style>
