<template>
  <section class="section-shaped overflow-hidden my-0">
    <div class="py-0">
      <div class="row">
        <div class="col-lg-12 mb-lg-auto">
          <div class="shadow-lg overflow-hidden">
            <b-carousel id="carousel1" controls indicators>
              <!-- Text slides with image -->
              <a :href="item.link" v-for="item in arrbanners.banners" :key="item.id">
                <b-carousel-slide :img-src="item.img" :caption="item.texto"></b-carousel-slide>
              </a>
            </b-carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import service from "../../services";

export default {
  data() {
    return {
      arrbanners: {
        banners: []
      }
    };
  },
  methods: {
    gotoAnyWhere(link) {
      console.log(link);
    }
  },
  mounted() {
    service.getJson("banners").then(res => {
      this.arrbanners = res;
    });
  },
  components: {}
};
</script>
<style>
</style>
