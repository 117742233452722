<template>
  <div>
    <b-card class="mb-5">
      <div class="row">
        <div class="col">
          <h4 class="text-uppercase">{{tipo}}</h4>
        </div>
        <div class="col text-right">
          <b-button @click="modalShow = !modalShow" variant="success" class="mb-2">+ Adicionar</b-button>
        </div>
      </div>

      <b-table striped hover :items="valoresShow" :fields="colunasShow">
        <template slot="Acoes" slot-scope="data">
          <button
            class="btn btn-danger"
            v-b-tooltip.hover
            title="Remover"
            v-on:click="deleteLinha(data.index)"
          >
            <i class="fa fa-trash"></i>
          </button>

          <button
            v-if="tipo=='reunioes'"
            class="btn btn-success"
            v-b-tooltip.hover
            title="Adicionar Fotos"
            v-on:click="addFile(data.index)"
          >
            +
            <i class="fa fa-image"></i>
          </button>
        </template>

        <template slot="icone" slot-scope="data">
          <i :class="valoresShow[data.index].icone"></i>
        </template>

        <template v-if="tipo=='logos'" slot="endereco" slot-scope="data">
          <img :src="valoresShow[data.index].endereco" height="60px">
        </template>

        <template v-if="tipo=='reunioes'" slot="fotoprincipal" slot-scope="data">
          <img :src="valoresShow[data.index].fotoprincipal" height="60px">
        </template>

        <template v-if="tipo=='parceiros'" slot="logo" slot-scope="data">
          <img :src="valoresShow[data.index].logo" height="200px">
        </template>

        <template slot="foto" slot-scope="data">
          <img :src="valoresShow[data.index].foto" height="200px">
        </template>

        <template v-if="tipo=='banners'" slot="img" slot-scope="data">
          <img :src="valoresShow[data.index].img" height="60px">
        </template>

        <template v-if="tipo=='depoimentos' || tipo=='textos'" slot="texto" slot-scope="data">
          <p v-html="valoresShow[data.index].texto"></p>
        </template>

        <template v-if="tipo=='reunioes'" slot="fotos" slot-scope="data">
          <p v-for="item in valoresShow[data.index].fotos" :key="item.id">
            <img :src="item" height="60px">
          </p>
        </template>

        <template slot="senha" slot-scope="data">
          <span v-for="item in valoresShow[data.index].senha.split('')" :key="item.id">*</span>
        </template>
      </b-table>
    </b-card>

    <b-modal size="lg" v-model="modalShow" hide-footer title="Adicionar Novo">
      <div v-if="uploading==true">
        <b>Arquivo esta sendo Enviado, aguarde ..</b>
        <b-progress :value="50" variant="info" striped height="20px" :animated="true" class="mt-2"></b-progress>
      </div>
      <img
        v-if="uploadedFile!='' && (getExtension(uploadedFile)=='png'||getExtension(uploadedFile)=='jpg')"
        :src="uploadedFile"
        style="width:100%"
      >
      <span v-else>{{uploadedFile}}</span>
      <vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
      <b-button
        v-on:click="adicionar()"
        variant="success"
        class="btn-block"
        v-if="aguarde==false"
      >Adicionar</b-button>
      <b-button variant="success" class="btn-block" v-else disabled>
        <i class="fa fa-spinner"></i> Aguarde...
      </b-button>
    </b-modal>

    <b-modal v-model="modalShowFoto" hide-footer title="Adicionar Novo">
      <div v-if="uploading==true">
        <b>Arquivo esta sendo Enviado, aguarde ..</b>
        <b-progress :value="50" variant="info" striped height="20px" :animated="true" class="mt-2"></b-progress>
      </div>
      <img
        v-if="uploadedFile!='' && (getExtension(uploadedFile)=='png'||getExtension(uploadedFile)=='jpg')"
        :src="uploadedFile"
        style="width:100%"
      >
      <span v-else>{{uploadedFile}}</span>
      <vue-form-generator :schema="schemaAux" :model="model" :options="formOptions"></vue-form-generator>
      <b-button
        v-on:click="adicionarAux()"
        variant="success"
        class="btn-block"
        v-if="aguarde==false"
      >Adicionar</b-button>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import service from "../../services";
import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";

export default {
  props: [
    "schema",
    "model",
    "tipo",
    "colunas",
    "uploadedFile",
    "uploading",
    "schemaAux"
  ],
  watch: {
    model(value) {
      this.geraTabela(value);
    }
  },
  data() {
    return {
      geral: {},
      indiceToAdd: 0,
      modalShow: false,
      modalShowFoto: false,
      aguarde: false,
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      },
      file: "",
      show: true,
      colunasShow: ["Acoes"],
      valoresShow: []
    };
  },
  methods: {
    getExtension(text) {
      var iLen = String(text).length;
      return String(text).substring(iLen, iLen - 3);
    },
    adicionarAux() {
      var arrTo = this.schemaAux.fields[0].alvo.split(".");
      if (this.model[arrTo[0]][this.indiceToAdd][arrTo[1]]) {
        this.model[arrTo[0]][this.indiceToAdd][arrTo[1]].push(
          this.uploadedFile
        );
      } else {
        this.model[arrTo[0]][this.indiceToAdd][arrTo[1]] = [];
        this.model[arrTo[0]][this.indiceToAdd][arrTo[1]].push(
          this.uploadedFile
        );
      }
      this.modalShowFoto = false;
      this.$emit("addedAux", {});
    },
    addFile(indice) {
      this.indiceToAdd = indice;
      this.modalShowFoto = true;
    },
    adicionar() {
      var itemadd = {};
      var arrcolu = [];
      for (let index = 0; index < this.colunasShow.length - 1; index++) {
        const element = this.colunasShow[index];
        arrcolu.push(element);
      }
      arrcolu.forEach(item => {
        itemadd[item] = this.valoresShow[item];

        if (this.valoresShow[item]) {
          if (typeof this.valoresShow[item] == "string")
            itemadd[item] = this.valoresShow[item].replace(
              /\r\n|\r|\n/g,
              "<br />"
            );
        }

        if (item == "icone" && this.tipo == "telefones") {
          switch (this.valoresShow.tipo) {
            case "celular":
              itemadd[item] = "fa fa-mobile";
              break;
            case "telefone":
              itemadd[item] = "fa fa-phone";
              break;
            case "whatsapp":
              itemadd[item] = "fa fa-whatsapp";
              break;
            default:
              itemadd[item] = "fa fa-phone";
              break;
          }
        }

        if (item == "data")
          itemadd[item] = moment(this.valoresShow[item]).format("DD/MM/YYYY");
      });
      var varItemUploaded = this.schema.fields.filter(
        x => x.isUploaded == true
      )[0];

      if (varItemUploaded) {
        var listModel = varItemUploaded.model.split(".");
        itemadd[listModel[listModel.length - 1]] = this.uploadedFile;
      }

      var varItemArray = this.schema.fields.filter(x => x.isArray == true);
      varItemArray.forEach(element => {
        var arraymodel = element.model.split(".");
        if (itemadd[arraymodel[arraymodel.length - 1]] != undefined)
          itemadd[arraymodel[arraymodel.length - 1]] = itemadd[
            arraymodel[arraymodel.length - 1]
          ].split(",");
      });

      this.modalShow = false;
      this.$emit("added", { tipo: this.tipo, itemadd: itemadd });
      itemadd = {};
    },
    geraTabela(array) {
      var tipoaux = this.tipo;
      this.valoresShow = array[tipoaux];
      if (this.schema.tableFields) {
        this.schema.tableFields.forEach(item => {
          this.colunasShow.unshift(item);
        });
      } else {
        if (this.valoresShow[0]) {
          Object.keys(this.valoresShow[0]).forEach(item => {
            this.colunasShow.unshift(item);
          });
        } else {
          Object.keys(this.schema.fields).forEach(item => {
            this.colunasShow.unshift(item);
          });
        }
      }
    },
    deleteLinha(indice) {
      for (var i = this.valoresShow.length - 1; i >= 0; i--) {
        if (indice === i) {
          this.valoresShow.splice(i, 1);
        }
      }
    }
  },
  mounted() {},
  components: {
    "vue-form-generator": VueFormGenerator.component
  }
};
</script>
