<template>
  <section class="section mt-4">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="row row-grid">
            <div class="col" v-for="item in arrresumo.resumo" :key="item.id">
              <card class="border-0" hover shadow body-classes="py-5 text-center">
                <icon v-if="item.icone!=''" :name="item.icone" type="primary" rounded class="mb-4"></icon>
                <h6 :class="`text-uppercase text-${item.classe}`">{{item.titulo}}</h6>
                <p v-if="item.texto!=''" class="description mt-3">{{item.texto}}</p>
                <div v-if="item.lista">
                  <div v-for="subitem in item.lista" :key="subitem.id">
                    <badge :type="item.classe" rounded>></badge>
                    {{subitem}}
                  </div>
                </div>
                <div v-if="item.link">
                  <router-link
                    v-if="!verificaLinkExterno(item.link.endereco)"
                    class="mt-4 btn"
                    :to="item.link.endereco"
                    :key="item.id"
                    :class="item.link.classe"
                  >
                    <span class="nav-link-inner--text">{{item.link.textolink}}</span>
                  </router-link>
                  <a
                    v-else
                    :key="item.id"
                    :href="item.link.endereco"
                    class="mt-4 btn"
                    :class="item.link.classe"
                  >
                    <span class="nav-link-inner--text">{{item.link.textolink}}</span>
                  </a>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import service from "../../services";
export default {
  data() {
    return {
      arrresumo: {}
    };
  },
  methods: {
    gotoAnyWhere(link) {
      console.log(link);
    },
    verificaLinkExterno(link) {
      if (link.substring(0, 7) == "http://") {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    service.getJson("resumosite").then(res => {
      this.arrresumo = res;
    });
  },
  components: {}
};
</script>
<style>
</style>
