<template>
  <div>
    <Banners></Banners>
    <ResumoEmpresa></ResumoEmpresa>
    <Depoimentos></Depoimentos>
    <Mapa></Mapa>
    <b-modal size="lg" v-model="show" hide-footer title="Atenção - COMUNICADO">
      <center>
        <img
          width="350px"
          src="https://s3.amazonaws.com/simplesbucket/Site2/logo.png"
          alt="Viva Hort"
          title="Viva Hort"
        />
      </center>
      <p class="text-right">Contagem, 22 de março de 2020.</p>

      <p>
        Prezados Clientes,
        Devido ao estado de calamidade pública decretado pelo governador Romeu
        Zema, visando a segurança de nossos funcionários e clientes, e em
        conformidade com a normativa de regulamentação de funcionamento do
        entreposto emitido pela a Ceasa Minas, COMUNICAMOS que iremos funcionar
        em horário reduzido de segunda-feira à sexta-feira das 04:00 (quatro horas)
        até 12:00 (doze horas).
      </p>
      <p>
        Pedimos a todos os clientes que sempre que possível encaminhem os pedidos
        de compra com antecedência para melhor atendê-los.
        Estaremos disponíveis para atendimento via WhatsApp e via E-mail sempre que
        necessário fora do horário comercial.
        <br />Seguem os contatos:
      </p>
      <p>
        <b>
          Warley Faria
          (31)9.9201-5031 - (31)9.9772-5231 /
          <a
            href="mailto:viva.ceasa@hotmail.com"
          >viva.ceasa@hotmail.com</a>
        </b>
      </p>
      <p>
        Atenciosamente,
        <br />Viva Frutas Ltda
        <br />Cnpj: 18.943.714/0001-07
        <br />Tel: (31)3058-0289
        <br />Site: www.vivahort.com.br
      </p>
    </b-modal>
  </div>
</template>

<script>
import Banners from "./components/Banners";
import ResumoEmpresa from "./components/ResumoEmpresa";
import Contadores from "./components/Contadores";
import Depoimentos from "./components/Depoimentos";
import Mapa from "./components/Mapa";

export default {
  name: "home",
  components: { Banners, ResumoEmpresa, Contadores, Depoimentos, Mapa },
  data() {
    return {
      show: false
    };
  },
  methods: {
    openModal() {
      this.show = true;
    }
  },
  mounted() {
    //this.openModal();
  }
};
</script>
