<template>
  <div>
    <listageral :schema="schemaTextos" :model="model" tipo="textos" @added="onAdd"></listageral>

    <listageral
      :schema="schemaFile"
      :model="model"
      tipo="time"
      @added="onAdd"
      :uploadedFile="uploadedFile"
      :uploading="uploading"
    ></listageral>

    <b-button
      type="submit"
      variant="success"
      class="btn-block"
      v-if="aguarde==false"
      v-on:click="sendForm"
    >Salvar</b-button>
    <b-button type="submit" variant="success" class="btn-block" v-else disabled>
      <i class="fa fa-spinner"></i> Aguarde...
    </b-button>
  </div>
</template>

<script>
import swal from "sweetalert";
import service from "../../services";
import listageral from "./ListaGeral";
import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";

export default {
  data() {
    return {
      files: new FormData(),
      geral: {},
      aguarde: false,
      uploadedFile: "",
      uploading: false,
      model: {},
      schemaTextos: {
        tableFields: ["texto", "titulo", "ordem"],
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "Titulo",
            model: "textos.titulo",
            required: true
          },
          {
            type: "textArea",
            inputType: "text",
            label: "Texto",
            model: "textos.texto",
            required: true,
            rows: 4
          },
          {
            type: "input",
            inputType: "text",
            label: "Ordem",
            model: "textos.ordem",
            required: true
          }
        ]
      },
      schemaFile: {
        tableFields: ["nome", "cargo", "foto"],
        fields: [
          {
            type: "upload",
            label: "Foto",
            id: "Photo",
            model: "file",
            inputName: "file",
            visible: () => {
              return this.uploadedFile == "" ? true : false;
            },
            onChanged: (model, schema, event, instance) => {
              this.selecionaArquivo(event);
            }
          },
          {
            type: "input",
            inputType: "text",
            label: "Nome",
            model: "time.nome",
            required: true
          },
          {
            type: "input",
            inputType: "text",
            label: "Cargo",
            model: "time.cargo",
            required: true
          },

          {
            type: "input",
            inputType: "hidden",
            label: "",
            model: "time.foto",
            visible: false,
            isUploaded: true
          }
        ]
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      },
      show: true
    };
  },
  methods: {
    selecionaArquivo(event) {
      this.files.set("file", event.target.files[0], event.target.files[0].name);
      this.uploading = true;
      service
        .upload("Upload", "Logo", this.files)
        .then(response => {
          this.uploadedFile = response;
          this.uploading = false;
        })
        .catch(function(error) {
          swal({
            title: "Oops!",
            text: "Ocorreu um problema, Tente novamente mais tarde",
            icon: "error",
            button: "Continuar..."
          });
        });
    },
    resetUpload() {
      this.uploadedFile = "";
      this.uploading = false;
    },
    onAdd(value) {
      this.model[value.tipo].push(value.itemadd);
      console.log(this.model);
      this.resetUpload();
    },
    sendForm() {
      this.aguarde = true;
      service
        .post("Adm", "EditJson", {
          site: process.env.VUE_APP_SITE,
          tipoForm: "empresa",
          dadoForm: this.model
        })
        .then(() => {
          this.aguarde = false;
          swal({
            title: "=)",
            text: "Dados Atualizados com Sucesso",
            icon: "success"
          });
        })
        .catch(error => {
          this.aguarde = false;
          swal({
            title: "Oops!",
            text: "Ocorreu um problema, Tente novamente mais tarde",
            icon: "error",
            button: "Continuar..."
          });
        });
    }
  },
  created() {
    service.getJson("empresa").then(res => {
      this.model = res;
    });
  },
  components: {
    "vue-form-generator": VueFormGenerator.component,
    listageral
  }
};
</script>
