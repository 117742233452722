<template>
  <section class="section bg-dark section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-lg-md">
      <div>
        <b-card no-body>
          <b-tabs pills card>
            <b-tab title="Dados Gerais" active>
              <geral></geral>
            </b-tab>
            <b-tab title="Banners">
              <banners></banners>
            </b-tab>
            <b-tab title="Textos">
              <textos></textos>
            </b-tab>
            <b-tab title="Depoimentos">
              <depoimentos></depoimentos>
            </b-tab>
            <b-tab title="Clientes">
              <parceiros></parceiros>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </section>
</template>
<script>
import geral from "./adm/Geral";
import banners from "./adm/Banners";
import depoimentos from "./adm/Depoimentos";
import parceiros from "./adm/Parceiros";
import textos from "./adm/Textos";
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  components: {
    geral,
    banners,
    depoimentos,
    parceiros,
    textos
  }
};
</script>
<style>
</style>
