<template>
  <footer class="footer" :class="classeRodape">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <router-link slot="brand" class="mr-lg-5" to="/">
            <img
              :src="logoRodape.endereco"
              :alt="logoRodape.title"
              :title="logoRodape.title"
              class="w-100"
            >
          </router-link>
        </div>
        <div class="col-md-10">
          <ul class="nav nav-footer justify-content-end">
            <template v-for="item in arrmenus.menus">
              <li :key="itemsubmenu.id" v-for="itemsubmenu in item.submenus">
                <template>
                  <router-link
                    v-if="!verificaLinkExterno(itemsubmenu.link)"
                    :to="itemsubmenu.link"
                    class="nav-link"
                    v-bind:style="styleGeral"
                    :key="itemsubmenu.id"
                  >{{itemsubmenu.texto}}</router-link>
                  <a
                    v-else
                    :key="itemsubmenu.id"
                    :href="itemsubmenu.link"
                    class="nav-link"
                    v-bind:style="styleGeral"
                  >{{itemsubmenu.texto}}</a>
                </template>
              </li>
              <li :key="item.id" v-if="!item.submenus">
                <router-link
                  v-if="!verificaLinkExterno(item.link)"
                  :to="item.link"
                  class="nav-link"
                  v-bind:style="styleGeral"
                  :key="item.id"
                >{{item.texto}}</router-link>
                <a v-else :key="item.id" :href="item.link" class="nav-link">{{item.texto}}</a>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div class="row row-grid my-md">
        <div class="col text-center">
          <div class="icon icon-shape icon-lg mb-3" :class="classeIconeRodape" round>
            <i class="fa fa-map-marker"></i>
          </div>
          <p>{{endereco}}</p>
        </div>

        <div class="col text-center">
          <div class="icon icon-shape icon-lg mb-3" :class="classeIconeRodape" round>
            <i class="fa fa-phone"></i>
          </div>
          <div class="mb-1" v-for="item in telefones" :key="item.id">
            <i :class="item.icone"></i>
            {{item.numero}}
          </div>
        </div>

        <div class="col text-center">
          <div class="icon icon-shape icon-lg mb-3" :class="classeIconeRodape" round>
            <i class="fa fa-envelope-o"></i>
          </div>
          <div class="mb-1" v-for="item in emails" :key="item.id">{{item.email}}</div>
        </div>

        <div v-if="arrmenus.redessociais.lenght >=1" class="col text-right btn-wrapper">
          <a
            v-for="item in arrmenus.redessociais"
            :key="item.id"
            class="mr-2"
            :class="geral.estrutura.classeRedesSociaisRodape"
            :href="item.link"
            target="_blank"
            rel="noopener"
            data-toggle="tooltip"
            :title="item.title"
          >
            <i :class="item.icone"></i>
            <span class="nav-link-inner--text d-lg-none">{{item.nome}}</span>
          </a>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12">
          <div class="copyright text-center">
            &copy; {{year}}
            <a
              href="https://www.pensesimples.com"
              target="_blank"
              rel="noopener"
              v-bind:style="styleGeral"
            >Pense Simples</a>.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import service from "../services";

export default {
  name: "app-footer",
  data() {
    return {
      arrmenus: {
        redessociais: []
      },
      logos: [],
      telefones: [],
      emails: [],
      logoRodape: {},
      classeRodape: "",
      classeIconeRodape: "",
      year: new Date().getFullYear(),
      endereco: "",
      geral: {},
      styleGeral: {
        color: ""
      }
    };
  },
  methods: {
    verificaLinkExterno(link) {
      if (link.substring(0, 7) == "http://") {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    service.getJson("menu").then(res => {
      this.arrmenus = res;
    });

    service.getJson("geral").then(res => {
      this.geral = res;
      this.classeRodape = this.geral.estrutura.classeRodape;
      this.classeIconeRodape = this.geral.estrutura.classeIconesRodape;
      this.telefones = this.geral.telefones;
      this.logos = this.geral.logos;
      this.emails = this.geral.emails;
      this.endereco = this.geral.endereco.enderecoCompleto;
      this.logoRodape = this.logos.filter(x => x.posicao == "rodape")[0];
      this.styleGeral = `color:${this.geral.estrutura.corPrincipal}`;
    });
  },
  components: {}
};
</script>
<style>
</style>
