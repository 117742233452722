<template>
  <div>
    <vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
    <listageral :schema="schemaEmail" :model="model" tipo="emails" @added="onAdd"></listageral>
    <listageral :schema="schemaPhone" :model="model" tipo="telefones" @added="onAdd"></listageral>
    <listageral :schema="schemaUsuarios" :model="model" tipo="usuariosLogin" @added="onAdd"></listageral>
    <listageral
      :schema="schemaFile"
      :model="model"
      tipo="logos"
      @added="onAdd"
      :uploadedFile="uploadedFile"
      :uploading="uploading"
    ></listageral>

    <b-button
      type="submit"
      variant="success"
      class="btn-block"
      v-if="aguarde==false"
      v-on:click="sendForm"
    >Salvar</b-button>
    <b-button type="submit" variant="success" class="btn-block" v-else disabled>
      <i class="fa fa-spinner"></i> Aguarde...
    </b-button>
  </div>
</template>

<script>
import swal from "sweetalert";
import service from "../../services";
import listageral from "./ListaGeral";
import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";

export default {
  data() {
    return {
      files: new FormData(),
      geral: {},
      aguarde: false,
      uploadedFile: "",
      uploading: false,
      model: {},
      schemaUsuarios: {
        fields: [
          {
            type: "input",
            inputType: "email",
            label: "Email",
            model: "usuariosLogin.email",
            required: true
          },
          {
            type: "input",
            inputType: "password",
            label: "Senha",
            model: "usuariosLogin.senha",
            required: true
          }
        ]
      },
      schemaFile: {
        fields: [
          {
            type: "upload",
            label: "Logo",
            id: "Photo",
            model: "file",
            inputName: "file",
            visible: () => {
              return this.uploadedFile == "" ? true : false;
            },
            onChanged: (model, schema, event, instance) => {
              this.selecionaArquivo(event);
            }
          },
          {
            type: "input",
            inputType: "text",
            label: "Posição",
            model: "logos.posicao",
            required: true
          },
          {
            type: "input",
            inputType: "text",
            label: "Title",
            model: "logos.title",
            required: true
          },
          {
            type: "input",
            inputType: "hidden",
            label: "",
            model: "logos.endereco",
            visible: false,
            isUploaded: true
          }
        ]
      },
      schemaEmail: {
        fields: [
          {
            type: "input",
            inputType: "email",
            label: "Email",
            model: "emails.email",
            required: true
          },
          {
            type: "select",
            label: "Tipo",
            model: "emails.tipo",
            required: true,
            values: ["principal", "orcamento"],
            default: "principal"
          }
        ]
      },
      schemaPhone: {
        fields: [
          {
            type: "masked",
            inputType: "text",
            label: "Telefone",
            model: "telefones.numero",
            required: true,
            hint: "(99) 99999-9999"
          },
          {
            type: "select",
            label: "Tipo Telefone",
            model: "telefones.tipo",
            required: true,
            values: ["celular", "telefone", "whatsapp"]
          }
        ]
      },
      schema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "Site",
            model: "nomeempresa",
            readonly: true
          },
          {
            type: "input",
            inputType: "text",
            label: "Endereço",
            model: "endereco.enderecoCompleto",
            placeholder: "Endereço",
            required: true
          },
          {
            type: "textArea",
            label: "Mapa",
            model: "endereco.urlMapa",
            required: true,
            placeholder: "Mapa",
            rows: 4
          },
          {
            type: "input",
            inputType: "number",
            label: "Altura do Mapa",
            model: "endereco.alturaMapa"
          },
          {
            type: "switch",
            label: "Exibir Telefone no Topo do Site",
            model: "estrutura.exibetelefonetopo",
            multi: true,
            readonly: false,
            featured: false,
            disabled: false,
            default: true,
            textOn: "Sim",
            textOff: "Não"
          }
        ]
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      },
      show: true
    };
  },
  methods: {
    selecionaArquivo(event) {
      this.files.set("file", event.target.files[0], event.target.files[0].name);
      this.uploading = true;
      service
        .upload("Upload", "Logo", this.files)
        .then(response => {
          this.uploadedFile = response;
          this.uploading = false;
        })
        .catch(function(error) {
          swal({
            title: "Oops!",
            text: "Ocorreu um problema, Tente novamente mais tarde",
            icon: "error",
            button: "Continuar..."
          });
        });
    },
    resetUpload() {
      this.uploadedFile = "";
      this.uploading = false;
    },
    onAdd(value) {
      this.model[value.tipo].push(value.itemadd);
      this.resetUpload();
    },
    sendForm() {
      this.aguarde = true;
      service
        .post("Adm", "EditJson", {
          site: process.env.VUE_APP_SITE,
          tipoForm: "geral",
          dadoForm: this.model
        })
        .then(() => {
          this.aguarde = false;
          swal({
            title: "=)",
            text: "Dados Atualizados com Sucesso",
            icon: "success"
          });
        })
        .catch(error => {
          this.aguarde = false;
          swal({
            title: "Oops!",
            text: "Ocorreu um problema, Tente novamente mais tarde",
            icon: "error",
            button: "Continuar..."
          });
        });
    }
  },
  created() {
    service.getJson("geral").then(res => {
      this.model = res;
    });
  },
  components: {
    "vue-form-generator": VueFormGenerator.component,
    listageral
  }
};
</script>
