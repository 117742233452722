<template>
  <footer class="footer" :class="classeRodape">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="copyright text-center">
            &copy; {{year}}
            <a
              href="https://www.pensesimples.com"
              target="_blank"
              rel="noopener"
              v-bind:style="styleGeral"
            >Pense Simples</a>.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import service from "../services";

export default {
  name: "app-footer",
  data() {
    return {
      classeRodape: "",
      year: new Date().getFullYear(),
      styleGeral: {
        color: ""
      }
    };
  },
  methods: {},
  created() {},
  components: {}
};
</script>
<style>
</style>
