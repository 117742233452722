<template>
  <section class="slice slice-lg p-5" :class="dadosContadores.classebg">
    <div class="container">
      <div class="mb-5 text-center">
        <h3 class="mt-4" :class="dadosContadores.titulo.classe">{{dadosContadores.titulo.texto}}</h3>
        <div class="fluid-paragraph mt-3">
          <p class="lead lh-180">{{dadosContadores.subtitulo}}</p>
        </div>
      </div>
      <!-- Milestones -->
      <div class="position-relative">
        <div class="row">
          <div
            v-for="item in dadosContadores.contadores"
            :key="item.id"
            class="col p-3 mb-5 rounded"
            :class="item.classebox"
          >
            <div class="text-center">
              <div class="mb-4">
                <icon
                  :name="item.icone"
                  class="mb-1"
                  size="lg"
                  :type="item.classeicone"
                  shadow
                  rounded
                ></icon>
              </div>
              <div class="h1" :class="item.classecontador">
                <numeroanimado :value="item.contador"></numeroanimado>
                <span class="counter-extra">{{item.extracontador}}</span>
              </div>
              <h3 class="h6 text-capitalize" :class="item.classelegenda">{{item.legenda}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import service from "../../services";
import numeroanimado from "./numeroanimado";

export default {
  data() {
    return {
      dadosContadores: {}
    };
  },
  methods: {
    gotoAnyWhere(link) {
      console.log(link);
    }
  },
  mounted() {
    service.getJson("contadores").then(res => {
      this.dadosContadores = res;
    });
  },
  components: {
    numeroanimado
  }
};
</script>
<style>
</style>
