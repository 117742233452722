/* eslint-disable */
import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const apiIP = process.env.VUE_APP_IP_API || 'https://simples.bbonebrasil.com.br';
const obj = {}
import geral from './2/geral.json' 
obj.geral = geral
import banners from './2/banners.json' 
obj.banners = banners
import calltoaction from './2/calltoaction.json' 
obj.calltoaction = calltoaction
import contato from './2/contato.json' 
obj.contato = contato
import depoimentos from './2/depoimentos.json' 
obj.depoimentos = depoimentos
import empresa from './2/empresa.json' 
obj.empresa = empresa
import menu from './2/menu.json' 
obj.menu = menu
import parceiros from './2/parceiros.json' 
obj.parceiros = parceiros
import resumosite from './2/resumosite.json' 
obj.resumosite = resumosite
import utilidades from './2/utilidades.json' 
obj.utilidades = utilidades


export default {
    getJson(jsonName) {
        return new Promise(
            (resolve, reject) => {
                try {
                    resolve(obj[jsonName])

                } catch (e) {
                    reject(e);
                }
            }
        );
    },
    getAll(controllerName, extraParams, action = 'list') {
        return new Promise(
            (resolve, reject) => {
                try {
                    var apiRestPort = extraParams.port;
                    var endpoint = `${apiIP}:${apiRestPort}/${controllerName}/${action}`;

                    if (extraParams.PageNumber != undefined) {
                        if (action == '') {
                            endpoint = endpoint + 'Page/' + extraParams.PageNumber
                        } else {

                            endpoint = endpoint + '/' + extraParams.PageNumber
                        }
                    }

                    console.log(endpoint)

                    axios.get(endpoint, {
                            extraParams
                        })
                        .then(function (response) {
                            resolve(response);
                        })
                        .catch(function (error) {
                            reject(error);
                        })
                } catch (e) {
                    reject(e);
                }
            }
        );
    },

    getOne(controllerName, extraParams) {
        return new Promise(
            (resolve, reject) => {
                try {
                    var apiRestPort = extraParams.port;
                    const endpoint = `${apiIP}::${apiRestPort}/${controllerName}/${extraParams.PrimaryKey}/${extraParams.PageNumber}`;
                    console.log(endpoint)
                    axios.get(endpoint, {
                            extraParams
                        })
                        .then(function (response) {
                            resolve(response);
                        })
                        .catch(function (error) {
                            reject(error);
                        })
                } catch (e) {
                    reject(e);
                }
            }
        );
    },

    getFilter(controllerName, extraParams) {
        return new Promise(
            (resolve, reject) => {
                try {
                    var apiRestPort = extraParams.port;
                    const endpoint = `${apiIP}:${apiRestPort}/${controllerName}/filter/${extraParams.SearchText}?page=${extraParams.PageNumber}`;

                    axios.get(endpoint, {
                            extraParams
                        })
                        .then(function (response) {
                            resolve(response);
                        })
                        .catch(function (error) {
                            reject(error);
                        })
                } catch (e) {
                    reject(e);
                }
            }
        );
    },

    post(controllerName, action = 'create', data, extraParams) {
        return new Promise(
            (resolve, reject) => {
                try {

                    if (extraParams) {
                        var apiRestPort = extraParams.port;
                        var endpoint = `${apiIP}:${apiRestPort}/${controllerName}/${action}`;
                    } else {
                        var endpoint = `${apiIP}/${controllerName}/${action}`;
                    }
                    //console.log(data)
                    axios.post(endpoint, data)
                        .then(function (response) {
                            resolve(response);
                        })
                        .catch(function (error) {
                            reject(error.response.data.error.errorMessage);
                        })
                } catch (e) {
                    console.log(e)
                    reject(e);
                }
            }
        );
    },

    upload(controllerName, action, data, extraParams) {
        return new Promise(
            (resolve, reject) => {
                try {

                    if (extraParams) {
                        var apiRestPort = extraParams.port;
                        var endpoint = `${apiIP}:${apiRestPort}/${controllerName}/${action}`;
                    } else {
                        var endpoint = `${apiIP}/${controllerName}/${ process.env.VUE_APP_SITE }/${action}`;
                    }
                    //console.log(endpoint)

                    axios.post(endpoint, data, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(function (response) {
                            resolve(response.data);
                        })
                        .catch(function (error) {
                            reject(error.response.data.error.errorMessage);
                        })
                } catch (e) {
                    console.log(e)
                    reject(e);
                }
            }
        );
    },


    put(controllerName, action = 'update', data, extraParams) {
        return new Promise(
            (resolve, reject) => {
                try {
                    var apiRestPort = extraParams.port;
                    var endpoint = `${apiIP}:${apiRestPort}/${controllerName}/${action}`;

                    axios.put(endpoint, data)
                        .then(function (response) {
                            resolve(response);
                        })
                        .catch(function (error) {
                            reject(error.response.data.error.errorMessage);
                        })
                } catch (e) {
                    reject(e);
                }
            }
        );
    }

}
