<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section mb-0" :style="`background-image: url(${contato.banner_bg})`">
        <div class="container mt-2">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12 pt-5">
                <h1 class="display-3 text-white my-5 py-5 text-center">Orçamento</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->

      <section class="section section-components">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="card shadow pl-3 pr-3 py-5">
                <h3
                  class="h4 text-danger font-weight-bold mb-4 text-center"
                >Preecha o Formulário para receber um Orçamento</h3>
                <form @submit="formSubmit" name="orcamento" data-netlify=true data-netlify-recaptcha="true" netlify-honeypot="bot-field" netlify v-if="show">
                  
                  <b-form-group id="input-group-2" label="Nome" label-for="input-2">
                    <b-form-input id="input-2" v-model="form.Nome" required placeholder="Seu Nome" ></b-form-input>
                  </b-form-group>
                  <p hidden>
                    <label>ignore: <input :model="'form.bot-field'" /></label>
                </p>
                  <div class="form-row">
                    <b-form-group label="Email:" class="col-lg-12">
                      <b-form-input
                        v-model="form.Email"
                        type="email"
                        required
                        placeholder="Email Válido"
                        name="email"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="form-row">
                    <b-form-group label="Telefone:" class="col-lg-6">
                      <b-form-input
                        v-model="form.Telefone"
                        placeholder="Telefone"
                        v-mask="'(##) ####-####'"
                        name="telefone"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Celular:" class="col-lg-6">
                      <b-form-input
                        v-model="form.Celular"
                        required
                        placeholder="Celular"
                        v-mask="'(##) #####-####'"
                        name="celular"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="form-row">
                    <b-form-group label="Orçamento:" class="col-lg-12">
                      <b-form-textarea
                        id="textarea"
                        required
                        v-model="form.Orcamento"
                        placeholder="Em que podemos te ajudar?"
                        name="mensagem"
                        rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </div>
                  <div data-netlify-recaptcha="true"></div>
                  <b-button
                    type="submit"
                    variant="success"
                    class="btn-block"
                    v-if="aguarde==false"
                  >Enviar</b-button>
                  <b-button type="submit" variant="success" class="btn-block" v-else disabled>
                    <i class="fa fa-spinner"></i> Aguarde...
                  </b-button>
                </form>
                <!--
                <b-card class="mt-3" header="Form Data Result">
                  <pre class="m-0">{{ form }}</pre>
                </b-card>
                -->
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card shadow pl-2 pr-2 py-5">
                <h3 class="h4 text-danger font-weight-bold mb-4 text-center">Telefones</h3>
                <ul class="list-group">
                  <li class="list-group-item" v-for="item in geral.telefones" :key="item.id">
                    <i :class="item.icone"></i> &nbsp;
                    <span class="font-weight-normal">{{item.numero}}</span>
                  </li>
                </ul>
                <hr>
                <h3 class="h4 text-danger font-weight-bold mb-4 text-center">Emails</h3>
                <ul class="list-group">
                  <li class="list-group-item" v-for="item in geral.emails" :key="item.id">
                    <span class="font-weight-normal">{{item.email}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <calltoaction></calltoaction>
    <Mapa></Mapa>
  </div>
</template>

<script>
import Mapa from "./components/Mapa";

import calltoaction from "./components/calltoaction";
import moment from "moment";
import axios from "axios";
import swal from "sweetalert";
import dotenv from "dotenv";
import service from "../services";

export default {
  data() {
    return {
      aguarde: false,
      contato: {},
      geral: {},
      form: {
        Tipo:'Orçamento',
        Nome: "",
        Data: moment().format("DD/MM/YYYY HH:mm:ss"),
        Telefone: "",
        Celular: "",
        Email: "",
        Orcamento: "",
        access_key:"c57f296e-96a0-47ad-b4b2-89948abb2fe4"
      },
      show: true
    };
  },
  methods: {
    encode(data) {
          return Object.keys(data)
              .map(
                  key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
              )
              .join('&');
      },
      formSubmit(evt) {
        this.aguarde = true;
        evt.preventDefault();
          fetch('https://api.web3forms.com/submit', {
              method: 'POST',
              headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              },
              body: JSON.stringify(this.form) ,
          })
          .then(() => {console.log("Form successfully submitted")
    this.aguarde = false;
          swal({
            title: "=)",
            text:
              "Seu Email foi enviado com sucesso, entraremos em contato o mais rápido possivel",
            icon: "success"
          });
          this.form.Nome = "";
          this.form.Telefone = "";
          this.form.Celular = "";
          this.form.Email = "";
          this.form.Assunto = "";
          this.form.Mensagem = "";})
              .catch(error => alert(error));
      },
    onSubmit(evt) {
      this.aguarde = true;
      evt.preventDefault();
      service
        .post("Emails", "SendEmail", {
          site: process.env.VUE_APP_SITE,
          tipoForm: "Orçamento",
          dadoForm: this.form
        })
        .then(() => {
          this.aguarde = false;
          swal({
            title: "=)",
            text:
              "Seu Email foi enviado com sucesso, entraremos em contato o mais rápido possivel",
            icon: "success"
          });
          this.form.Nome = "";
          this.form.Telefone = "";
          this.form.Celular = "";
          this.form.Email = "";
          this.form.Assunto = "";
          this.form.Mensagem = "";
        })
        .catch(error => {
          this.aguarde = false;
          swal({
            title: "Oops!",
            text: "Ocorreu um problema, Tente novamente mais tarde",
            icon: "error",
            button: "Continuar..."
          });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.Nome = "";
      this.form.Telefone = "";
      this.form.Celular = "";
      this.form.Email = "";
      this.form.Orcamento = "";
      this.form.Mensagem = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  mounted() {
    service.getJson("contato").then(res => {
      this.contato = res;
    });

    service.getJson("geral").then(res => {
      this.geral = res;
    });
  },
  components: { calltoaction, Mapa }
};
</script>
