<template>
  <section class="do-map-link-section">
    <div class>
      <div class="row">
        <div class="col-sm-12">
          <iframe
            :src="dadosGeral.endereco.urlMapa"
            width="100%"
            :height="dadosGeral.endereco.alturaMapa"
            frameborder="0"
            style="border:0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import service from "../../services";
export default {
  data() {
    return {
      dadosGeral: {
        endereco: {}
      }
    };
  },
  methods: {
    gotoAnyWhere(link) {
      console.log(link);
    }
  },
  mounted() {
    service.getJson("geral").then(res => {
      this.dadosGeral = res;
    });
  },
  components: {}
};
</script>
<style>
</style>
