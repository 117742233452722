<template>
  <div>
    <listageral
      :schema="schemaFile"
      :model="model"
      :tipo="tipoGeral"
      @added="onAdd"
      :uploadedFile="uploadedFile"
      :uploading="uploading"
    ></listageral>

    <b-button
      type="submit"
      variant="success"
      class="btn-block"
      v-if="aguarde==false"
      v-on:click="sendForm"
    >Salvar</b-button>
    <b-button type="submit" variant="success" class="btn-block" v-else disabled>
      <i class="fa fa-spinner"></i> Aguarde...
    </b-button>
  </div>
</template>

<script>
import swal from "sweetalert";
import service from "../../services";
import listageral from "./ListaGeral";
import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";

export default {
  data() {
    return {
      files: new FormData(),
      tipoGeral: "parceiros",
      aguarde: false,
      uploadedFile: "",
      uploading: false,
      model: {},
      schemaFile: {
        fields: [
          {
            type: "upload",
            label: "Logo",
            id: "Photo",
            model: "file",
            inputName: "file",
            visible: () => {
              return this.uploadedFile == "" ? true : false;
            },
            onChanged: (model, schema, event, instance) => {
              this.selecionaArquivo(event);
            }
          },
          {
            type: "input",
            inputType: "text",
            label: "Nome",
            model: "parceiros.nome",
            required: true
          },
          {
            type: "input",
            inputType: "text",
            label: "Área de Atuação",
            model: "parceiros.atuacao"
          },
          {
            type: "input",
            inputType: "hidden",
            label: "",
            model: "parceiros.logo",
            visible: false,
            isUploaded: true
          }
        ]
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      },
      show: true
    };
  },
  methods: {
    selecionaArquivo(event) {
      this.files.set("file", event.target.files[0], event.target.files[0].name);
      this.uploading = true;
      service
        .upload("Upload", this.tipoGeral, this.files)
        .then(response => {
          this.uploadedFile = response;
          this.uploading = false;
        })
        .catch(function(error) {
          swal({
            title: "Oops!",
            text: "Ocorreu um problema, Tente novamente mais tarde",
            icon: "error",
            button: "Continuar..."
          });
        });
    },
    resetUpload() {
      this.uploadedFile = "";
      this.uploading = false;
    },
    onAdd(value) {
      console.log(this.model);
      this.model[value.tipo].push(value.itemadd);
      this.resetUpload();
    },
    sendForm() {
      this.aguarde = true;
      service
        .post("Adm", "EditJson", {
          site: process.env.VUE_APP_SITE,
          tipoForm: this.tipoGeral,
          dadoForm: this.model
        })
        .then(() => {
          this.aguarde = false;
          swal({
            title: "=)",
            text: "Dados Atualizados com Sucesso",
            icon: "success"
          });
        })
        .catch(error => {
          this.aguarde = false;
          swal({
            title: "Oops!",
            text: "Ocorreu um problema, Tente novamente mais tarde",
            icon: "error",
            button: "Continuar..."
          });
        });
    }
  },
  created() {
    service.getJson(this.tipoGeral).then(res => {
      this.model = res;
    });
  },
  components: {
    "vue-form-generator": VueFormGenerator.component,
    listageral
  }
};
</script>
