<template>
  <section class="section section-lg pt-5">
    <div class="container">
      <card
        :style="`background-image: url(${calltoaction.call.banner_bg})`"
        :gradient="calltoaction.call.class"
        no-body
        shadow-size="lg"
        class="border-0"
      >
        <div class="p-5">
          <div class="row align-items-center">
            <div class="col-lg-8">
              <h3 class="text-white">{{calltoaction.call.titulo}}</h3>
              <p class="lead text-white mt-3">{{calltoaction.call.subtitulo}}</p>
            </div>
            <div class="col-lg-3 ml-lg-auto">
              <router-link :to="calltoaction.call.link" :class="calltoaction.call.classebotao">
                <span class="nav-link-inner--text">{{calltoaction.call.textobotao}}</span>
              </router-link>
            </div>
          </div>
        </div>
      </card>
    </div>
  </section>
</template>
<script>
import service from "../../services";

export default {
  data() {
    return {
      calltoaction: {
        call: {
          link: "#"
        }
      }
    };
  },
  methods: {
    gotoAnyWhere(link) {
      console.log(link);
    }
  },
  created() {
    service.getJson("calltoaction").then(res => {
      this.calltoaction = res;
    });
  },
  components: {}
};
</script>
<style>
</style>
